import { Injectable } from '@angular/core';

import { HttpService } from './http.service';

declare var Stripe: any;

@Injectable({
    providedIn: 'root'
})
export class PaymentService {
    public stripeKey: string;
    public paypalKey: string;
    public paypalEnv: string;
    public stripe: any;
    public elements: any;
    public secure = false;
    public initialized = false;
    public fees: { min: number, fee: number }[] = [];
    public profiles: string[] = [];
    public currency = 'GBP';

    constructor(
        private http: HttpService
    ) { }

    public init(p: string, res: any): void {
        this.initialized = true;

        const version = this.http.mode;
        const threshold = { ...res.booking_fee.threshold };
        const type = p || 'default';

        const profiles = res[type][version];

        this.fees = Object.keys(threshold).map(min => ({ min: +min, fee: +threshold[min] }));
        this.profiles = profiles.map(item => item.processor);
        this.paypalEnv = this.http.mode === 'test' ? 'sandbox' : 'production';

        if (this.profiles.includes('stripe')) {
            const stripe = profiles.find(item => item.processor === 'stripe');

            this.stripeKey = stripe.key;
            this.secure = stripe['3d_secure'];

            this.stripe = Stripe(this.stripeKey);
            this.elements = this.stripe.elements();
        }

        if (this.profiles.includes('paypal')) {
            const paypal = profiles.find(item => item.processor === 'paypal');

            this.paypalKey = paypal.key;

            this.loadScript(
                `https://www.paypal.com/sdk/js?client-id=${this.paypalKey}&disable-funding=card,credit,bancontact&currency=${this.currency}&intent=authorize`
            );
        }
    }

    private loadScript(url: string) {
        if (document.querySelector(`script[src="${url}"]`)) return;

        const script = document.createElement('script') as any;

        script.type = 'text/javascript';
        script.src = url;

        document.getElementsByTagName('head')[0].appendChild(script);
    }
}
